<template>
  <div>
    <header
      class="header white-bg"
      v-if="
        !(
          $route.name == 'VoyadoDashboard' ||
          $route.name == 'ManageDealUpdateCreate' ||
          $route.name == 'ManageDealUpdateById' ||
          $route.name == 'copydeal'
        )
      "
      :class="headerClass"
    >
      <!-- <router-link :to="{ name: 'Home' }">
        <img src="../../assets/images/Voyado_logo.svg" alt class="logo" />
      </router-link> -->

      <div class="title-nav">{{ $store.state.title }}</div>

      <div class="top-nav">
        <div class="nav top-menu">
          <div class="flex user-info">
            <model-select
              v-if="
                $store.state.cognitoInfo.companylist &&
                $store.state.cognitoInfo.companylist.length > 1
              "
              v-model="$store.state.cognitoInfo.companyid"
              :options="$store.state.cognitoInfo.companylist"
              placeholder="Select Company"
              id="ddlCompany"
              class="form-control ddlcompany-header mr-1"
              @update:modelValue="onCompanyChange"
              :selected="$store.state.cognitoInfo.companyid"
              :searchable="true"
            ></model-select>

            <span
              v-if="
                $store.state.cognitoInfo.companylist &&
                $store.state.cognitoInfo.companylist.length == 1
              "
              class="current-company"
              >{{ $store.state.cognitoInfo.companyname }}</span
            >
            <model-select
              v-model="selectedLanguage"
              :options="languages"
              placeholder="Select Language"
              id="ddlLanguage"
              class="form-control ddlLanguage-header mr-1"
              style="width: auto"
              @update:modelValue="onLanguageChange"
              option-label="text"
              option-value="value"
              :searchable="true"
            ></model-select>
            <span class="username-text"
              >{{ lmodel.welcome1 }},
              {{ $store.state.cognitoInfo.displayname }}</span
            >
            <span>
              <SplitButton
                :model="items"
                class="p-button-text p-button-secondary icon"
              ></SplitButton>
            </span>
          </div>
        </div>
      </div>
    </header>

    <aside v-clickOutside="handleClickOutside">
      <div
        id="sidebar"
        :class="{ pinned: pinMenu, unpinned: !pinMenu }"
        style="overflow-y: auto"
      >
        <div class="menu">
          <div class="box">
            <router-link
              v-if="$store.state.cognitoInfo.role != 'Accessadmin'"
              :to="{ name: 'VoyadoDashboard' }"
            >
              <img src="../../assets/images/Voyado_logo.svg" alt class="logo" />
            </router-link>
            <router-link v-else :to="{ name: 'UserList' }">
              <img src="../../assets/images/Voyado_logo.svg" alt class="logo" />
            </router-link>
          </div>
          <div
            class="box"
            v-if="$store.state.cognitoInfo.role !== 'Accessadmin'"
          >
            <router-link
              style="text-decoration: none"
              :to="{ name: 'VoyadoDashboard' }"
              id="VoyadoDashboard"
              :class="{
                'menu-item selected': OverviewTabArray.includes(CurrPath),
                'menu-item': !OverviewTabArray.includes(CurrPath),
              }"
            >
              <i
                :class="{
                  ' overview-active selected':
                    OverviewTabArray.includes(CurrPath),
                  ' overview-icon': !OverviewTabArray.includes(CurrPath),
                }"
              ></i>
            </router-link>
            <span class="menu-text">{{ lmodel.overview1 }}</span>
          </div>
          <!-- <div
            class="box"
            v-show="$store.state.cognitoInfo.role != 'Accessadmin'"
          >
            <router-link
              style="text-decoration: none"
              :to="{ name: 'Home' }"
              id="Home"
              :class="{
                'menu-item selected': HomeTabArray.includes(CurrPath),
                'menu-item': !HomeTabArray.includes(CurrPath),
              }"
            >
              <i
                :class="{
                  'dashboard-active selected': HomeTabArray.includes(CurrPath),
                  'dashboard-icon': !HomeTabArray.includes(CurrPath),
                }"
              ></i>
            </router-link>
            <span class="menu-text">{{ lmodel?.menuDashboard }}</span>
          </div> -->

          <div
            v-show="false"
            class="box"
            @click="toggleSidebar('deal')"
            v-if="$store.state.cognitoInfo.role != 'Accessadmin'"
          >
            <router-link
              style="text-decoration: none"
              :to="{ name: '' }"
              id="deal-wizard"
              :class="{
                'menu-item selected': DealTabArray.includes(CurrPath),
                'menu-item': !DealTabArray.includes(CurrPath),
              }"
            >
              <i
                :class="{
                  'plus-active selected': DealTabArray.includes(CurrPath),
                  plus: !DealTabArray.includes(CurrPath),
                }"
              ></i>
            </router-link>
            <span class="menu-text">{{ lmodel?.menuDealWizard }}</span>
          </div>

          <div
            class="box"
            @click="toggleSidebar('config')"
            v-show="$store.state.cognitoInfo.role != 'Accessadmin'"
          >
            <router-link
              style="text-decoration: none"
              :to="{ name: '' }"
              id="configuration"
              :class="{
                'menu-item selected': ConfigTabArray.includes(CurrPath),
                'menu-item': !ConfigTabArray.includes(CurrPath),
              }"
            >
              <i
                :class="{
                  'cogs-active selected': ConfigTabArray.includes(CurrPath),
                  'cogs-icon': !ConfigTabArray.includes(CurrPath),
                }"
              ></i>
            </router-link>
            <span class="menu-text">{{ lmodel?.menuConfiguration }}</span>
          </div>
          <div
            class="box"
            @click="toggleSidebar('accelerator')"
            v-if="
              (this.$store.state.leads.isEcom == true ||
                this.$store.state.leads.isElevate == true ||
                this.$store.state.leads.isLead == true) &&
              this.$store.state.cognitoInfo.companyid != '_all'
            "
            v-show="
              $store.state.cognitoInfo.role != 'Accessadmin' &&
              $store.state.cognitoInfo.role != 'User'
            "
          >
            <router-link
              style="text-decoration: none"
              :to="{ name: '' }"
              id="acc"
              :class="{
                'menu-item selected': AccTabArray.includes(CurrPath),
                'menu-item': !AccTabArray.includes(CurrPath),
              }"
            >
              <i
                :class="{
                  'acc-active selected': AccTabArray.includes(CurrPath),
                  'acc-icon': !AccTabArray.includes(CurrPath),
                }"
              ></i>
            </router-link>
            <span class="menu-text">{{ lmodel?.accelerator }}</span>
          </div>
          <!-- <div
          v-if="$store.state.cognitoInfo.role == 'Superadmin'"
            class="box"
            @click="isExpandHide()"
          >
            <router-link
            
              style="text-decoration: none"
               :to="{ name: 'acceleratorsite' }"
              id="acceleratorsite"
               :class="{
                'menu-item selected': AcceleratorSitesTabArray.includes(CurrPath),
                'menu-item': !AcceleratorSitesTabArray.includes(CurrPath),
              }"
          
            >
              <i
                :class="{
                  'pen-active-icon selected': AcceleratorSitesTabArray.includes(CurrPath),
                  'pen-icon': !AcceleratorSitesTabArray.includes(CurrPath),
                }"
              ></i>
            </router-link>
            <span class="menu-text">{{ lmodel?.menuAccelerator }}</span>
          </div> -->
          <div
            class="box"
            @click="toggleSidebar('newdeal')"
            v-show="$store.state.cognitoInfo.role != 'Accessadmin'"
          >
            <router-link
              style="text-decoration: none"
              :to="{ name: '' }"
              id="newdeal"
              :class="{
                'menu-item selected': ManageDealTabArray.includes(CurrPath),
                'menu-item': !ManageDealTabArray.includes(CurrPath),
              }"
            >
              <i
                :class="{
                  'pen-active-icon selected':
                    ManageDealTabArray.includes(CurrPath),
                  'pen-icon': !ManageDealTabArray.includes(CurrPath),
                }"
              ></i>
            </router-link>
            <span
              class="menu-text"
              style="
                display: inline-block;
                max-width: 55px;
                word-wrap: break-word;
              "
              >{{ lmodel.menuManageDeals }}</span
            >
          </div>

          <!-- <div
            class="box"
            @click="isExpandHide()"
            v-show="$store.state.cognitoInfo.role != 'Accessadmin'"
          >
            <router-link
              style="text-decoration: none"
              :to="{ name: 'ManageDealListNew' }"
              id="manage-deals"
              :class="{
                'menu-item selected': ManageDealTabArray.includes(CurrPath),
                'menu-item': !ManageDealTabArray.includes(CurrPath),
              }"
            >
              <i
                :class="{
                  'pen-active-icon selected':
                    ManageDealTabArray.includes(CurrPath),
                  'pen-icon': !ManageDealTabArray.includes(CurrPath),
                }"
              ></i>
            </router-link>
            <span
              class="menu-text"
              style="
                display: inline-block;
                max-width: 55px;
                word-wrap: break-word;
              "
              >{{ lmodel?.menuManageDeals }}</span
            >
          </div> -->

          <div
            class="box"
            @click="toggleSidebar('insight')"
            v-show="$store.state.cognitoInfo.role != 'Accessadmin'"
          >
            <router-link
              style="text-decoration: none"
              :to="{ name: '' }"
              id="insight-reports"
              :class="{
                'menu-item selected': ReportTabArray.includes(CurrPath),
                'menu-item': !ReportTabArray.includes(CurrPath),
              }"
            >
              <i
                :class="{
                  'report-active selected': ReportTabArray.includes(CurrPath),
                  'report-icon': !ReportTabArray.includes(CurrPath),
                }"
              ></i>
            </router-link>
            <span class="menu-text">{{ lmodel?.menuInsightReports }}</span>
          </div>

          <div
            class="box"
            v-if="$store.state.cognitoInfo.role != 'User'"
            @click="isExpandHide()"
          >
            <router-link
              style="text-decoration: none"
              :to="{ name: 'UserList' }"
              id="user-list"
              :class="{
                'menu-item selected': UserTabArray.includes(CurrPath),
                'menu-item': !UserTabArray.includes(CurrPath),
              }"
            >
              <i
                :class="{
                  'user-active selected': UserTabArray.includes(CurrPath),
                  'user-icon': !UserTabArray.includes(CurrPath),
                }"
              ></i>
            </router-link>
            <span class="menu-text">{{ lmodel?.menuUsers }}</span>
          </div>

          <!-- <div
            class="box"
            @click="isExpandHide()"
            v-show="$store.state.cognitoInfo.role != 'Accessadmin'"
          >
            <router-link
              style="text-decoration: none"
              :to="{ name: 'Translation' }"
              id="administration"
              :class="{
                'menu-item selected': AudienceTabArray.includes(CurrPath),
                'menu-item': !AudienceTabArray.includes(CurrPath),
              }"
            >
              <i
                :class="{
                  'admin-active selected': AudienceTabArray.includes(CurrPath),
                  'admin-icon': !AudienceTabArray.includes(CurrPath),
                }"
              ></i>
            </router-link>
            <span class="menu-text">{{ lmodel?.menuTranslation }}</span>
          </div> -->
          <div
            class="box"
             v-if="
                $store.state.cognitoInfo.role != 'User' &&
                $store.state.cognitoInfo.role != 'Singletenantadmin' &&
                 $store.state.cognitoInfo.role != 'Accessadmin'
              "
            @click="toggleSidebar('operation')"
          >
            <router-link
              style="text-decoration: none"
              :to="{ name: '' }"
              id="operation-wizard"
              :class="{
                'menu-item selected': ContactTabArray.includes(CurrPath),
                'menu-item': !ContactTabArray.includes(CurrPath),
              }"
            >
              <i
                :class="{
                  'operate-dash-active selected':
                    ContactTabArray.includes(CurrPath),
                  'operate-dash-icon': !ContactTabArray.includes(CurrPath),
                }"
              ></i>
            </router-link>
            <span class="menu-text">{{ lmodel?.menuOprationWizard }}</span>
          </div>

          <!-- <div
            class="box"
            v-if="$store.state.cognitoInfo.role !== 'Accessadmin'"
            @click="isExpandHide()"
          >
            <router-link
              style="text-decoration: none"
              :to="{ name: 'ModifyDeal' }"
              id="deal-list"
              :class="{
                'menu-item selected': DealListTabArray.includes(CurrPath),
                'menu-item': !DealListTabArray.includes(CurrPath),
              }"
            >
              <i
                :class="{
                  'list-active selected': DealListTabArray.includes(CurrPath),
                  'list-icon': !DealListTabArray.includes(CurrPath),
                }"
              ></i>
            </router-link>
            <span class="menu-text">{{ lmodel.dealList1 }}</span>
          </div> -->
          <!-- 
          <div
            class="box"
            v-if="
              $store.state.cognitoInfo.role == 'Superadmin' ||
              $store.state.cognitoInfo.role == 'Systemadmin'
            "
          >
            <router-link
              style="text-decoration: none"
              :to="{ name: 'acceleratordashboard' }"
              id="acceleratordashboard"
              :class="{
                'menu-item selected': AcceleratorTabArray.includes(CurrPath),
                'menu-item': !AcceleratorTabArray.includes(CurrPath),
              }"
            >
              <i
                :class="{
                  ' overview-active selected':
                    AcceleratorTabArray.includes(CurrPath),
                  ' overview-icon': !AcceleratorTabArray.includes(CurrPath),
                }"
              ></i>
            </router-link>
            <span class="menu-text">{{ lmodel.menuAccelerator }}</span>
          </div> -->
         <div
            class="box"
            @click="toggleSidebar('acceleratordashboard')"
            v-if="
              ($store.state.cognitoInfo.role != 'User' &&
                $store.state.cognitoInfo.role != 'Singletenantadmin' &&
                $store.state.cognitoInfo.role != 'Accessadmin' 
                ) &&
              (this.$store.state.leads.isEcom == true ||
                this.$store.state.leads.isElevate == true) &&
              this.$store.state.cognitoInfo.companyid != '_all'
            "
          >
            <router-link
              style="text-decoration: none"
              :to="{ name: '' }"
              id="acceleratordashboard"
              :class="{
                'menu-item selected': AcceleratorTabArray.includes(CurrPath),
                'menu-item': !AcceleratorTabArray.includes(CurrPath),
              }"
            >
              <i
                :class="{
                  ' overview-active selected':
                    AcceleratorTabArray.includes(CurrPath),
                  ' overview-icon': !AcceleratorTabArray.includes(CurrPath),
                }"
              ></i>
            </router-link>
            <span class="menu-text">{{ lmodel.menuAccelerator }}</span>
          </div>
          <div
            class="box"
            v-show="$store.state.cognitoInfo.role == 'Superadmin'"
          >
            <router-link
              style="text-decoration: none"
              :to="{ name: 'Home' }"
              id="Home"
              :class="{
                'menu-item selected': HomeTabArray.includes(CurrPath),
                'menu-item': !HomeTabArray.includes(CurrPath),
              }"
            >
              <i
                :class="{
                  'dashboard-active selected': HomeTabArray.includes(CurrPath),
                  'dashboard-icon': !HomeTabArray.includes(CurrPath),
                }"
              ></i>
            </router-link>
            <span class="menu-text">{{ lmodel?.menuDashboard }}</span>
          </div>
          <!-- <div
            class="box"
            v-if="
              $store.state.cognitoInfo.role == 'Superadmin' ||
              $store.state.cognitoInfo.role == 'Systemadmin'
            "
          >
            <router-link
              style="text-decoration: none"
              :to="{ name: 'VoyadoDashboard' }"
              id="VoyadoDashboard"
              :class="{
                'menu-item selected': OverviewTabArray.includes(CurrPath),
                'menu-item': !OverviewTabArray.includes(CurrPath),
              }"
            >
              <i
                :class="{
                  ' overview-active selected':
                    OverviewTabArray.includes(CurrPath),
                  ' overview-icon': !OverviewTabArray.includes(CurrPath),
                }"
              ></i>
            </router-link>
            <span class="menu-text">{{ lmodel.overview1 }}</span>
          </div> -->
          <div
            class="box"
            v-if="
              $store.state.cognitoInfo.role == 'Superadmin' ||
              $store.state.cognitoInfo.role == 'Systemadmin'
            "
          >
            <router-link
              style="text-decoration: none"
              :to="{ name: 'contentgenerator' }"
              id="contentgenerator"
              :class="{
                'menu-item selected':
                  ContentGeneratorTabArray.includes(CurrPath),
                'menu-item': !ContentGeneratorTabArray.includes(CurrPath),
              }"
            >
              <i
                :class="{
                  ' imageai-icon-select selected':
                    ContentGeneratorTabArray.includes(CurrPath),
                  ' imageai-icon': !ContentGeneratorTabArray.includes(CurrPath),
                }"
              ></i>
            </router-link>
            <span class="menu-text">{{ lmodel.contentGenerator1 }}</span>
          </div>
          <div
            class="box"
            @click="isExpandHide()"
            v-show="$store.state.cognitoInfo.role != 'Accessadmin'"
          >
            <router-link
              style="text-decoration: none"
              :to="{ name: 'Translation' }"
              id="administration"
              :class="{
                'menu-item selected': AudienceTabArray.includes(CurrPath),
                'menu-item': !AudienceTabArray.includes(CurrPath),
              }"
            >
              <i
                :class="{
                  'admin-active selected': AudienceTabArray.includes(CurrPath),
                  'admin-icon': !AudienceTabArray.includes(CurrPath),
                }"
              ></i>
            </router-link>
            <span class="menu-text">{{ lmodel?.menuTranslation }}</span>
          </div>
          <!-- <div class="box" v-if="$store.state.cognitoInfo.role == 'Superadmin'">
            <router-link
              style="text-decoration: none"
              :to="{ name: 'leadads' }"
              id="leadads"
              :class="{
                'menu-item selected':
                  LeadAdsTabArray.includes(CurrPath),
                'menu-item': !LeadAdsTabArray.includes(CurrPath),
              }"
            >
              <i
                :class="{
                  ' leadads-active selected':
                    LeadAdsTabArray.includes(CurrPath),
                  ' leadads-icon': !LeadAdsTabArray.includes(CurrPath),
                }"
              ></i>
            </router-link>
            <span class="menu-text">LeadAds</span>
          </div> -->
          <PrimeToast />
        </div>
      </div>

      <div
        :class="{
          'expanded-content': true,
          expanded: isExpanded,
        }"
        style="overflow-y: auto"
      >
        <div>
          <div v-show="visibleDealMenu">
            <div class="menu-head">{{ lmodel?.menuDealWizard }}</div>
            <router-link
              v-if="
                $store.state.cognitoInfo.role == 'Superadmin' ||
                $store.state.cognitoInfo.role == 'Admin' ||
                $store.state.cognitoInfo.role == 'Partner' ||
                $store.state.cognitoInfo.role == 'Admin'
              "
              :to="{ name: 'NewDealCreate' }"
              id="NewDealCreate"
              :class="{
                'menu-item1 selected': CurrPath == 'NewDealCreate',
                'menu-item1': CurrPath != 'NewDealCreate',
              }"
            >
              <span class="submenu-item" @click="isExpandHide()">
                <i class="plus"></i>{{ lmodel?.menuNewWizardPage }}</span
              >
            </router-link>
            <router-link
              :to="{ name: 'DealList' }"
              id="DealList"
              :class="{
                'menu-item1 selected': CurrPath == 'DealList',
                'menu-item1': CurrPath != 'DealList',
              }"
            >
              <span class="submenu-item" @click="isExpandHide()"
                ><i class="draft-icon"></i
                >{{ lmodel?.menuContinueFromDraft }}</span
              >
            </router-link>
          </div>

          <div v-show="visibleConfigurationMenu">
            <div class="menu-head">{{ lmodel?.subMenuConfiguration }}</div>
            <router-link
              v-if="
                $store.state.cognitoInfo.role == 'Superadmin' ||
                $store.state.cognitoInfo.role == 'Systemadmin'
              "
              :to="{ name: 'SiteList' }"
              id="SiteList"
              :class="{
                'menu-item1 selected':
                  CurrPath == 'SiteCreate' ||
                  CurrPath == 'SiteEdit' ||
                  CurrPath == 'SiteList',
                'menu-item1':
                  CurrPath != 'SiteCreate' ||
                  CurrPath != 'SiteEdit' ||
                  CurrPath != 'SiteList',
              }"
            >
              <span class="submenu-item" @click="isExpandHide()"
                ><i class="lists-icon"></i>{{ lmodel.newSite1 }}</span
              >
            </router-link>
            <router-link
              v-if="$store.state.cognitoInfo.role != 'User'"
              :to="{ name: 'NewSiteList' }"
              id="NewSiteList"
              :class="{
                'menu-item1 selected':
                  CurrPath == 'NewSiteCreate' ||
                  CurrPath == 'NewSiteEdit' ||
                  CurrPath == 'NewSiteList',
                'menu-item1':
                  CurrPath != 'NewSiteCreate' ||
                  CurrPath != 'NewSiteEdit' ||
                  CurrPath != 'NewSiteList',
              }"
            >
              <span class="submenu-item" @click="isExpandHide()"
                ><i class="lists-icon"></i>{{ lmodel?.menuSites }}</span
              >
            </router-link>

            <router-link
              v-if="
                $store.state.cognitoInfo.role != 'User' &&
                $store.state.cognitoInfo.role != 'Singletenantadmin'
              "
              :to="{ name: 'CompanyList' }"
              id="CompanyList"
              :class="{
                'menu-item1 selected':
                  CurrPath == 'CompanyCreate' ||
                  CurrPath == 'CompanyEdit' ||
                  CurrPath == 'CompanyList',
                'menu-item1':
                  CurrPath != 'CompanyCreate' ||
                  CurrPath != 'CompanyEdit' ||
                  CurrPath != 'CompanyList',
              }"
            >
              <span class="submenu-item" @click="isExpandHide()"
                ><i class="hotel-icon"></i>{{ lmodel?.menuCompany }}</span
              >
            </router-link>
            <a
              href="https://developer.voyado.com/en/onsite-messaging.html"
              target="_blank"
              id="Ho1me"
              :class="
                $store.state.activeRoute === 'Home'
                  ? 'menu-item1 selected'
                  : 'menu-item1'
              "
            >
              <span class="submenu-item" @click="isExpandHide()"
                ><i class="overview-icon"></i
                >{{ lmodel?.menuInstallation }}</span
              >
            </a>
          </div>

          <div v-show="visibleInsightMenu">
            <div class="menu-head">{{ lmodel?.subMenuInsightReports }}</div>
            <router-link
              :to="{ name: 'Performance' }"
              id="Performance"
              :class="{
                'menu-item1 selected': CurrPath == 'Performance',
                'menu-item1': CurrPath != 'Performance',
              }"
            >
              <span class="submenu-item" @click="isExpandHide()"
                ><i class="draft-icon"></i>{{ lmodel?.menuPerformance }}</span
              >
            </router-link>
            <router-link
              :to="{ name: 'Ambassadors' }"
              v-if="
                $store.state.cognitoInfo.role == 'Superadmin' ||
                $store.state.cognitoInfo.role == 'Systemadmin'
              "
              id="Ambassadors"
              :class="{
                'menu-item1 selected': CurrPath == 'Ambassadors',
                'menu-item1': CurrPath != 'Ambassadors',
              }"
            >
              <span class="submenu-item" @click="isExpandHide()"
                ><i class="user-plus-icon"></i
                >{{ lmodel?.menuMyAmbassadors }}</span
              >
            </router-link>
            <router-link
              :to="{ name: 'Friends' }"
              id="Friends"
              v-if="
                $store.state.cognitoInfo.role == 'Superadmin' ||
                $store.state.cognitoInfo.role == 'Systemadmin'
              "
              :class="{
                'menu-item1 selected': CurrPath == 'Friends',
                'menu-item1': CurrPath != 'Friends',
              }"
            >
              <span class="submenu-item" @click="isExpandHide()"
                ><i class="friend-icon"></i>{{ lmodel?.menuMyFriends }}</span
              >
            </router-link>
            <router-link
              :to="{ name: 'Relations' }"
              id="Relations"
              v-if="
                $store.state.cognitoInfo.role == 'Superadmin' ||
                $store.state.cognitoInfo.role == 'Systemadmin'
              "
              :class="{
                'menu-item1 selected': CurrPath == 'Relations',
                'menu-item1': CurrPath != 'Relations',
              }"
            >
              <span class="submenu-item" @click="isExpandHide()"
                ><i class="heart-circle-icon"></i>{{ lmodel?.relations }}</span
              >
            </router-link>
          </div>

          <div v-show="visibleOperationDashboardMenu">
            <div class="menu-head">{{ lmodel?.subMenuOprationWizard }}</div>
            <router-link
              v-if="
              $store.state.cognitoInfo.role == 'Superadmin' ||
              $store.state.cognitoInfo.role == 'Systemadmin'
            "
              :to="{ name: 'FailedContact' }"
              id="FailedContact"
              :class="{
                'menu-item1 selected': CurrPath == 'FailedContact',
                'menu-item1': CurrPath != 'FailedContact',
              }"
            >
              <span class="submenu-item" @click="isExpandHide()"
                ><i class="file-icon"></i>{{ lmodel?.menuFailContact }}</span
              >
            </router-link>
            <router-link
             v-if="
              $store.state.cognitoInfo.role == 'Superadmin' ||
              $store.state.cognitoInfo.role == 'Systemadmin'
            "
              :to="{ name: 'Error' }"
              id="Error"
              :class="{
                'menu-item1 selected': CurrPath == 'Error',
                'menu-item1': CurrPath != 'Error',
              }"
            >
              <span class="submenu-item" @click="isExpandHide()"
                ><i class="error-icon"></i>{{ lmodel?.menuErrorSummary }}</span
              >
            </router-link>
            <router-link
                  v-if="
                 $store.state.cognitoInfo.role == 'Superadmin' ||
                 $store.state.cognitoInfo.role == 'Systemadmin' ||
                  $store.state.cognitoInfo.role == 'Partner' ||
                  $store.state.cognitoInfo.role == 'Admin' ||
                  $store.state.cognitoInfo.role == 'Alltenantadmin'
            "
              :to="{ name: 'calllog' }"
              id="calllogsite"
              :class="{
                'menu-item1 selected':
                  CurrPath == 'calllogsite' ||
                  CurrPath == 'calllogdashboard' ||
                  CurrPath == 'calllogview',
                'menu-item1':
                  CurrPath == 'calllogsite' ||
                  CurrPath == 'calllogdashboard' ||
                  CurrPath != 'calllogview',
              }"
            >
              <span class="submenu-item" @click="isExpandHide()"
                ><span class="call-icon"></span>{{ lmodel.callLog }}</span
              >
            </router-link>
          </div>
          <div v-show="visibleNewDealMenu">
            <div class="menu-head">{{ lmodel?.subMenuManageDeals }}</div>
            <router-link
              style="text-decoration: none"
              :to="{ name: 'ModifyDeal' }"
              id="deal-list"
              :class="{
                'menu-item1 selected': DealListTabArray.includes(CurrPath),
                'menu-item1': !DealListTabArray.includes(CurrPath),
              }"
            >
              <!-- <i
                :class="{
                  'list-active selected': DealListTabArray.includes(CurrPath),
                  'list-icon': !DealListTabArray.includes(CurrPath),
                }"
              ></i> -->
              <span class="submenu-item" @click="isExpandHide()"
                ><i class="pen-icon"></i>{{ lmodel.menuManageDealsNew }}</span
              >
            </router-link>
            <router-link
              style="text-decoration: none"
              :to="{ name: 'ManageDealListNew' }"
              id="manage-deals"
              :class="{
                'menu-item1 selected': ManageDealTabArray.includes(CurrPath),
                'menu-item1': !ManageDealTabArray.includes(CurrPath),
              }"
            >
              <!-- <i
                :class="{
                  'pen-active-icon selected':
                    ManageDealTabArray.includes(CurrPath),
                  'pen-icon': !ManageDealTabArray.includes(CurrPath),
                }"
              ></i> -->
              <span class="submenu-item" @click="isExpandHide()"
                ><i class="pen-icon"></i>{{ lmodel.menuManageDealsOld }}</span
              >
            </router-link>
            <!-- <div v-show="visibleNewDealMenu"> -->
          </div>
          <div v-show="visibleAcceleratorMenu">
            <div class="menu-head">{{ lmodel?.subAccelerator }}</div>
            <router-link
              v-if="this.$store.state.leads.isEcom"
              :to="{ name: 'acceleratorsite' }"
              id="acceleratorsite"
              :class="{
                'menu-item1 selected':
                  CurrPath == 'acceleratorsite' ||
                  CurrPath == 'acceleratoredit',
                'menu-item1':
                  CurrPath != 'acceleratorsite' ||
                  CurrPath != 'acceleratoredit',
              }"
            >
              <span class="submenu-item" @click="isExpandHide()"
                ><i class="lists-icon"></i
                >{{ lmodel?.submenuAccelerator }}</span
              >
            </router-link>

            <router-link
              v-if="this.$store.state.leads.isLead"
              :to="{ name: 'leadadslist' }"
              id="leadadslist"
              :class="{
                'menu-item1 selected':
                  CurrPath == 'leadadslist' || CurrPath == 'leadads',
                'menu-item1':
                  CurrPath != 'leadadslist' || CurrPath != 'leadads',
              }"
            >
              <span class="submenu-item" @click="isExpandHide()"
                ><i class="lists-icon"></i>{{ lmodel?.menuLeads }}</span
              >
            </router-link>
            <router-link
              v-if="this.$store.state.leads.isElevate"
              :to="{ name: 'elevatelist' }"
              id="elevatelist"
              :class="{
                'menu-item1 selected':
                  CurrPath == 'elevatelist' || CurrPath == 'elevateedit',
                'menu-item1':
                  CurrPath != 'elevatelist' || CurrPath != 'elevateedit',
              }"
            >
              <span class="submenu-item" @click="isExpandHide()"
                ><i class="lists-icon"></i>{{ lmodel?.menuVoyadoElevate }}</span
              >
            </router-link>
          </div>

          <div v-show="visibleAcceleratorDashboardMenu">
            <div class="menu-head">{{ lmodel?.menuAccelerator }}</div>
            <router-link
              v-if="this.$store.state.leads.isElevate"
              :to="{ name: 'productsite' }"
              id="productsite"
              :class="{
                'menu-item1 selected':
                  CurrPath == 'productsite' ||
                  CurrPath == 'productdashboard' ||
                  CurrPath == 'productview',
                'menu-item1':
                  CurrPath != 'productsite' ||
                  CurrPath != 'productdashboard' ||
                  CurrPath != 'productview',
              }"
            >
              <span class="submenu-item" @click="isExpandHide()"
                ><i class="lists-icon"></i>{{ lmodel.productDashboard }}</span
              >
            </router-link>
            <router-link
              v-if="this.$store.state.leads.isEcom"
              :to="{ name: 'ordersite' }"
              id="ordersite"
              :class="{
                'menu-item1 selected':
                  CurrPath == 'ordersite' ||
                  CurrPath == 'orderdashboard' ||
                  CurrPath == 'orderview',
                'menu-item1':
                  CurrPath != 'ordersite' ||
                  CurrPath != 'orderdashboard' ||
                  CurrPath != 'orderview',
              }"
            >
              <span class="submenu-item" @click="isExpandHide()"
                ><i class="lists-icon"></i>{{ lmodel.orderDashboard }}</span
              >
            </router-link>
            <router-link
              v-if="this.$store.state.leads.isEcom"
              :to="{ name: 'contactsite' }"
              id="contactsite"
              :class="{
                'menu-item1 selected':
                  CurrPath == 'contactsite' ||
                  CurrPath == 'contactdashboard' ||
                  CurrPath == 'contactview',
                'menu-item1':
                  CurrPath != 'contactsite' ||
                  CurrPath != 'contactdashboard' ||
                  CurrPath != 'contactview',
              }"
            >
              <span class="submenu-item" @click="isExpandHide()"
                ><i class="lists-icon"></i>{{ lmodel.contactDashboard }}</span
              >
            </router-link>
            <router-link
              v-if="this.$store.state.leads.isEcom"
              :to="{ name: 'inventorysite' }"
              id="inventorysite"
              :class="{
                'menu-item1 selected':
                  CurrPath == 'inventorysite' ||
                  CurrPath == 'inventorydashboard' ||
                  CurrPath == 'inventoryview',
                'menu-item1':
                  CurrPath != 'inventorysite' ||
                  CurrPath != 'inventorydashboard' ||
                  CurrPath != 'inventoryview',
              }"
            >
              <span class="submenu-item" @click="isExpandHide()"
                ><i class="lists-icon"></i> {{ lmodel.inventories }}</span
              >
            </router-link>
            <router-link
              v-if="this.$store.state.leads.isElevate"
              :to="{ name: 'acceleratordashboardmarkets' }"
              id="marketsite"
              :class="{
                'menu-item1 selected':
                  CurrPath == 'marketsite' ||
                  CurrPath == 'marketdashboard' ||
                  CurrPath == 'marketview',
                'menu-item1':
                  CurrPath != 'marketsite' ||
                  CurrPath != 'marketdashboard' ||
                  CurrPath != 'marketview',
              }"
            >
              <span class="submenu-item" @click="isExpandHide()"
                ><i class="lists-icon"></i> {{ lmodel.markets }}</span
              >
            </router-link>
             <router-link
                v-if="this.$store.state.leads.isEcom"
                :to="{ name: 'acceleratordashboardreceipt' }"
                id="receiptsite"
                :class="{
                  'menu-item1 selected':
                    CurrPath == 'receiptsite' ||
                    CurrPath == 'receiptdashboard' ||
                    CurrPath == 'receiptview',
                  'menu-item1':
                    CurrPath != 'receiptsite' ||
                    CurrPath != 'receiptdashboard' ||
                    CurrPath != 'receiptview',
                }"
              >
                <span class="submenu-item" @click="isExpandHide()"
                  ><i class="lists-icon"></i>{{ lmodel.receipts }}</span
                >
              </router-link>
          </div>
          <!-- </div> -->
        </div>

        <div class="pin-menu">
          <span>{{ lmodel.pinMenu1 }}</span>
          <ToggleButton
            v-model="pinMenu"
            onIcon="toggle-on"
            offIcon="toggle-off"
            class="toggle-btn"
            @click="this.$emit('call')"
          />
        </div>
      </div>
    </aside>
  </div>
</template>

<script>
import DataLayerService from "@/services/data-layer.service";
import { ModelSelect } from "vue-search-select";
import axios from "axios";
import Store from "@/store/index";
const baseURL = Store.state.apiURL;

export default {
  components: {
    "model-select": ModelSelect,
  },
  data() {
    return {
      languages: [], // To hold language options
      selectedLanguage: "en",
      url: process.env.VUE_APP_MANAGER_API_URL,
      HomeTabArray: ["Home"],
      DealTabArray: ["NewDealCreate", "modifydeal", "DealEditNew", "DealList"],
      AcceleratorTabArray: [
        "productsite",
        "productdashboard",
        "productview",
        "ordersite",
        "orderdashboard",
        "orderview",
        "contactsite",
        "contactdashboard",
        "contactview",
        "inventorysite",
        "inventorydashboard",
        "inventoryview",
        "marketsite",
        "marketdashboard",
        "marketview",
        "receiptsite",
        "receiptdashboard",
        "receiptview",        
      ],
      OverviewTabArray: ["VoyadoDashboard"],
      ContentGeneratorTabArray: ["contentgenerator"],
      // LeadAdsTabArray: ["leadads"],
      DealListTabArray: ["ModifyDeal"],
      isSidebarExpanded: false,
      AccTabArray: [
        "acceleratorsite",
        "acceleratoredit",
        "leadads",
        "leadadslist",
        "elevateedit",
        "elevatelist",
      ],
      ConfigTabArray: [
        "SiteList",
        "SiteCreate",
        "SiteEdit",
        "SiteCopy",
        "CompanyCreate",
        "CompanyList",
        "CompanyEdit",
        "NewSiteList",
      ],
      ManageDealTabArray: [
        "ManageDealListNew",
        "ManageDealList",
        "EditDeal",
        "CopyDeal",
        "DealLogs",
        "DealSearch",
        "ManageDealUpdateById",
        "ManageDealUpdateCreate",
        "copydeal",
        "ModifyDeal",
      ],
      ReportTabArray: ["Performance", "Ambassadors", "Friends", "Relations"],
      ContactTabArray: [
        "contactlist",
        "errorsummary",
        "FailedContact",
        "Error",
        "calllogsite",
        "calllogdashboard",
        "calllogview",
      ],
      UserTabArray: ["UserList"],
      // AcceleratorSitesTabArray: ["acceleratorsite","leadads"],
      AudienceTabArray: ["Audiences", "AudienceCreate", "Translation"],
      items: [
        {
          label: "Account Settings",
        },
        {
          label: "Privacy Policy",
          command: () => {
            window.open("https://voyado.com/legal/privacy-policy/", "_blank");
          },
        },
        {
          separator: true,
        },
        {
          label: "Logout",
          icon: "fa fa-sign-out",
          to: "/logout",
          command: () => {
            localStorage.removeItem("headersidebar");
          },
        },
      ],
      isExpanded: false,
      visibleDealMenu: false,
      visibleConfigurationMenu: false,
      visibleInsightMenu: false,
      visibleOperationDashboardMenu: false,
      visibleNewDealMenu: false,
      visibleAcceleratorMenu: false,
      visibleAcceleratorDashboardMenu: false,
      lmodel: {}, // Store language-specific labels here
      pinMenu: false,
      expandedMenu: null,
    };
  },
  props: {
    is_not_expand: {
      type: Boolean,
      required: true,
    },
  },
  //   watch: {
  //   $route(to, from) {
  //     if (to.name !== from.name) {
  //       this.getData();
  //     }
  //   }
  // },
  // watch: {
  //   "$store.state.selectedLanguage": {
  //     handler(newValue, oldValue) {
  //       if (newValue !== oldValue) {
  //         if (this.$route.name != undefined) {
  //           this.getData();
  //         }
  //       }
  //     },
  //     deep: true,
  //     immediate: true,
  //   },
  // },
  async created() {
    // this.$nextTick(async () => {
    //   if (this.$route.name != undefined) {
    //     await this.getData();
    //   }
    // });
    this.$store.state.prevCompanyId = this.$store.state.cognitoInfo.companyid;
  },
  async mounted() {
    const savedLanguages = localStorage.getItem("availableLanguages");
    if (savedLanguages) {
      this.languages = JSON.parse(savedLanguages);
    } else {
      // if (this.$route.name !== undefined) {
      this.fetchLanguages();
      // }
    }

    const savedLanguage = localStorage.getItem("selectedLanguage");
    if (savedLanguage) {
      // console.log(11);
      this.selectedLanguage = savedLanguage;
    } else {
      // console.log(22);
      const url = this.$route.fullPath;
      const redirect = this.getParamValue(url, "redirect");
      if (redirect) {
        const decodedRedirect = decodeURIComponent(redirect);
        const language = this.getParamValue(decodedRedirect, "language");
        if (language) {
          const extractedLanguage = this.extractLanguageCode(language);
          this.selectedLanguage = extractedLanguage;
        }
      }
    }
    // setTimeout(async () => {
    // if (this.$route.name !== undefined) {
    if (this.$route.name && this.$route.name !== "Login") {
      await this.getData(false);
      await this.getLeadToggle();
    } else if (this.$store.state.headerSidebar) {
      await this.localdata();
    }

    //   }
    // }, 200);
  },
  computed: {
    CurrPath() {
      return this.$route.name;
    },
    headerClass() {
      return this.pinMenu
        ? "header white-bg expand"
        : "header white-bg not-expand";
    },
  },
  methods: {
    async getLeadToggle() {
      if (
        this.$store.state.cognitoInfo.companyid &&
        this.$store.state.cognitoInfo.companyid !== "" &&
        this.$store.state.cognitoInfo.companyid !== null &&
        this.$store.state.cognitoInfo.companyid !== undefined
      ) {
        await DataLayerService.getById(
          "/Company/GetCompanyEnables",
          this.$store.state.cognitoInfo.companyid
        ).then((result) => {
          if (result && result.isSuccess == true) {
            this.$store.commit("setLeadsData", result.isLeadAdsEnable);
            this.$store.commit("setEcomData", result.isEComEnable);
            this.$store.commit(
              "setisElevateEnableData",
              result.isElevateEnable
            );
            this.$store.commit(
              "setisShowOnSiteMessages",
              result.isShowOnSiteMessage
            );
            this.$store.commit(
              "setMultiPromotionsEnabled",
              result.isMultiPromotionEnable
            );
            this.$store.commit(
              "setCompanystoreId",
              this.$store.state.cognitoInfo.companyid
            );
          }
        });
      }
    },
    async localdata() {
      const headerSidebar = this.$store.state.headerSidebar;

      if (headerSidebar && Object.keys(headerSidebar).length > 0) {
        this.lmodel = headerSidebar;
      } else {
        console.log("headerSidebar is empty or not set");
      }
    },

    extractLanguageCode(locale) {
      if (locale) {
        const normalizedLocale = locale.trim().toLowerCase();
        const languageCode = normalizedLocale.split(/[-_]/)[0];
        return languageCode;
      }
    },
    getParamValue(url, paramName) {
      if (
        url !== undefined &&
        url !== null &&
        paramName !== undefined &&
        paramName !== null
      ) {
        const regex = new RegExp(`[?&]${paramName}=([^&]*)`);
        const match = url.match(regex);
        return match ? decodeURIComponent(match[1]) : null;
      }
    },
    async fetchLanguages() {
      try {
        const _userInfo = this.$store.state.cognitoInfo;

        if (
          _userInfo &&
          Object.keys(_userInfo).length !== 0 &&
          this.$store.state.menudataInfo &&
          Object.keys(this.$store.state.menudataInfo).length !== 0
        ) {
          const url = `${baseURL}/site/GetManagerLanguages`;
          const accessToken = Store.state.cognitoInfo.token;
          const jwtHeader = {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              CompanyId: Store.state.cognitoInfo.companyid || "",
              crossDomain: true,
              "content-type": "application/x-www-form-urlencoded",
            },
          };

          const response = await axios.get(url, jwtHeader);

          if (response && Array.isArray(response.data)) {
            this.languages = response.data;
            localStorage.setItem(
              "availableLanguages",
              JSON.stringify(this.languages)
            );
          } else {
            console.error("Invalid response format for languages:", response);
          }
        } else {
          console.warn("User or menu data is missing.");
        }
      } catch (error) {
        console.error("Error fetching languages:", error);
      }
    },

    async getData(value) {
      // console.log("called", value);

      try {
        let languageCode = localStorage.getItem("selectedLanguage") || "en";
        const response = await DataLayerService.get(
          `/resource/${languageCode}`
        );
        if (response && Object.keys(response).length > 0) {
          const localHeader = {
            welcome1: response.Welcome1,
            dealList1: response.DealList1,
            acceleratordashboard: response.acceleratordashboard,
            overview1: response.Overview1,
            contentGenerator1: response.ContentGenerator1,
            newSite1: response.NewSite1,
            menuManageDeals: response.MenuManageDeals,
            menuManageDealsNew: response.menuManageDealsNew,
            menuManageDealsOld: response.menuManageDealsOld,
            pinMenu1: response.PinMenu1,
            menuDashboard: response.MenuDashboard,
            menuConfiguration: response.MenuConfiguration,
            menuInsightReports: response.MenuInsightReports,
            menuUsers: response.MenuUsers,
            menuTranslation: response.MenuTranslation,
            menuOprationWizard: response.menuOprationWizard,
            menuDealWizard: response.MenuDealWizard,
            menuNewWizardPage: response.MenuNewWizardPage,
            menuContinueFromDraft: response.MenuContinueFromDraft,
            menuSites: response.MenuSites,
            menuAccelerator: response.menuAccelerator,
            submenuAccelerator: response.submenuAccelerator,
            menuLeads: response.menuLeads,
            menuCompany: response.MenuCompany,
            menuInstallation: response.MenuInstallation,
            menuPerformance: response.MenuPerformance,
            menuMyAmbassadors: response.MenuMyAmbassadors,
            menuMyFriends: response.MenuMyFriends,
            relations: response.relations,
            menuFailContact: response.MenuFailContact,
            menuErrorSummary: response.menuErrorSummary,
            accelerator: response.accelerator,
            menuVoyadoElevate: response.menuVoyadoElevate,
            subMenuConfiguration: response.subMenuConfiguration,
            subAccelerator: response.subAccelerator,
            subMenuManageDeals: response.subMenuManageDeals,
            subMenuInsightReports: response.subMenuInsightReports,
            subMenuOprationWizard: response.subMenuOprationWizard,
            productDashboard: response.productDashboard,
            orderDashboard: response.orderDashboard,
            contactDashboard: response.contactDashboard,
            inventories: response.inventories,
            callLog: response.callLog,
            markets: response.markets,
            receipts: response.receipts,
          };
          // localStorage.setItem("headersidebar", JSON.stringify(localHeader));
          this.$store.commit("setHeaderSidebar", localHeader);

          const updatedLModel = {};
          Object.keys(response).forEach((key) => {
            const lowerCaseKey = key.charAt(0).toLowerCase() + key.slice(1);
            updatedLModel[lowerCaseKey] = response[key];
          });
          this.lmodel = updatedLModel;
          if (value == true) {
            window.location.reload();
          }
        } else {
          console.warn("No data returned from the API.");
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          console.error(
            "Unauthorized access. Please check authentication:",
            error
          );
          // Optionally redirect to login or display a user-friendly error
        } else {
          console.error("Error fetching data:", error);
        }
      }
    },
    async onLanguageChange(newLanguage) {
      this.selectedLanguage = newLanguage;
      localStorage.setItem("selectedLanguage", newLanguage);
      this.$store.commit("setSelectedLanguage", newLanguage);
      await this.getData(true);
    },
    isExpandHide() {
      this.isExpanded = null;
    },
    toggleSidebar(menu) {
      this.isExpanded = !this.isExpanded;

      if (menu === "deal") {
        this.visibleDealMenu = true;
        this.visibleConfigurationMenu = false;
        this.visibleInsightMenu = false;
        this.visibleOperationDashboardMenu = false;
        this.visibleNewDealMenu = false;
        this.visibleAcceleratorMenu = false;
        this.visibleAcceleratorDashboardMenu = false;
        // }
      } else if (menu === "config") {
        this.visibleConfigurationMenu = true;
        this.visibleDealMenu = false;
        this.visibleInsightMenu = false;
        this.visibleOperationDashboardMenu = false;
        this.visibleNewDealMenu = false;
        this.visibleAcceleratorMenu = false;
        this.visibleAcceleratorDashboardMenu = false;
      } else if (menu === "insight") {
        this.visibleInsightMenu = true;
        this.visibleDealMenu = false;
        this.visibleConfigurationMenu = false;
        this.visibleOperationDashboardMenu = false;
        this.visibleNewDealMenu = false;
        this.visibleAcceleratorMenu = false;
        this.visibleAcceleratorDashboardMenu = false;
      } else if (menu === "operation") {
        this.visibleOperationDashboardMenu = true;
        this.visibleDealMenu = false;
        this.visibleInsightMenu = false;
        this.visibleConfigurationMenu = false;
        this.visibleNewDealMenu = false;
        this.visibleAcceleratorMenu = false;
        this.visibleAcceleratorDashboardMenu = false;
      } else if (menu === "newdeal") {
        this.visibleNewDealMenu = true;
        this.visibleOperationDashboardMenu = false;
        this.visibleDealMenu = false;
        this.visibleInsightMenu = false;
        this.visibleConfigurationMenu = false;
        this.visibleAcceleratorMenu = false;
        this.visibleAcceleratorDashboardMenu = false;
      } else if (menu === "accelerator") {
        this.visibleAcceleratorMenu = true;
        this.visibleNewDealMenu = false;
        this.visibleOperationDashboardMenu = false;
        this.visibleDealMenu = false;
        this.visibleInsightMenu = false;
        this.visibleConfigurationMenu = false;
        this.visibleAcceleratorDashboardMenu = false;
      } else if (menu === "acceleratordashboard") {
        this.visibleAcceleratorDashboardMenu = true;
        this.visibleAcceleratorMenu = false;
        this.visibleNewDealMenu = false;
        this.visibleOperationDashboardMenu = false;
        this.visibleDealMenu = false;
        this.visibleInsightMenu = false;
        this.visibleConfigurationMenu = false;
      }
    },
    emitTogglePinMenu() {
      this.$emit("toggle-pin-menu", this.pinMenu);
    },
    handleClickOutside() {
      if (!this.pinMenu) {
        this.isExpanded = null;
      }
    },
    navigate(routeKey) {
      switch (routeKey) {
        case "Home":
          this.$router.push({ name: "Home" });
          break;
        case "dealWizard":
          this.expandedMenu = routeKey;
          break;
        case "configuration":
          this.expandedMenu = routeKey;
          break;
        case "newdeal":
          this.expandedMenu = routeKey;
          break;
        case "managedeallistnew":
          this.$router.push({ name: "managedeallistnew" });
          break;
        case "insightreports":
          this.expandedMenu = routeKey;
          break;
        case "operationdash":
          this.expandedMenu = routeKey;
          break;
        default:
          break;
      }
    },
    async onCompanyChange(item) {
      if (this.$store.state.prevCompanyId != item) {
        this.$store.commit("replaceCompanyId", item);

        await DataLayerService.getById("/Company/GetCompanyEnables", item).then(
          (result) => {
            if (result && result.isSuccess == true) {
              this.$store.commit("setLeadsData", result.isLeadAdsEnable);
              this.$store.commit("setEcomData", result.isEComEnable);
              this.$store.commit(
                "setisElevateEnableData",
                result.isElevateEnable
              );
              this.$store.commit(
                "setisShowOnSiteMessages",
                result.isShowOnSiteMessage
              );
              this.$store.commit(
                "setMultiPromotionsEnabled",
                result.isMultiPromotionEnable
              );
              this.$store.commit("setCompanystoreId", item);
            }
          }
        );

        var routeName = this.$route.name;
        if (routeName == "SiteEdit" || routeName == "SiteCreate") {
          this.$router.push({ name: "SiteList" });
        } else if (routeName == "DealEditNew" || routeName == "DealCreate") {
          this.$router.push({ name: "DealList" });
        } else if (routeName == "EditDeal") {
          this.$router.push({ name: "ManageDealList" });
        } else if (routeName == "UserEdit" || routeName == "UserCreate") {
          this.$router.push({ name: "UserList" });
        } else {
          if (routeName == "Home") this.$store.commit("resetDashboardLastSync");
          this.$router.go();
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@use "@/assets/scss/headerbarnew.scss";
@import "@/assets/css/global.css";

::v-deep(.dashboard-style) {
  .fa {
    color: #4cb87d !important;
  }
}

:deep(.icon) {
  .pi-chevron-down:before {
    content: url("../../assets/images/user-icon.png");
    color: #21211f !important;
  }
}
:deep(.p-dropdown) {
  height: calc(1.5em + 0.75rem + 7px);
  width: 360px;
  line-height: 1.5;
  font-size: 14px;
  border: 1px solid #c0c0bc;
  border-radius: 0.25rem;
  color: #777 !important;
  text-decoration: none;
  box-shadow: none;
  background: none;
}
.ui.selection.dropdown {
  min-height: 14px;
  height: calc(1.5em + 0.75rem + 7px);
  width: 360px;
  line-height: 1.5;
  font-size: 14px;
  border: 1px solid #c0c0bc;
  border-radius: 0.25rem;
  color: #777 !important;
  text-decoration: none;
  box-shadow: none;
  background: none;
}

:deep(.fa-plus.selected) {
  color: #fff;
}

.submenu-item {
  display: flex;
  align-items: center;
  font-family: "Voyado Text";
  font-weight: 400;
  padding: 10px;
  cursor: pointer;
  font-size: 14.22px;
  color: #21211f;
  transition: background-color 0.3s, color 0.3s;
  gap: 5px;

  &:hover {
    background-color: #f5f5f5;
    color: #791a3f;
    border: 1px;
    border-radius: 20px;
  }

  i {
    margin-right: 5px;
    font-size: 16px;
  }
}

.expand {
  margin-left: 340px;
  transition-timing-function: ease-in-out;
  transition-duration: 0.3s;
}
.not-expand {
  margin-left: 90px;
  transition-timing-function: ease-in-out;
  transition-duration: 0.3s;
}
</style>
